import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import BookingQrScanner from './BookingQrScanner';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import { useToasts } from 'react-toast-notifications';
import UseConfirmModal from '../Common/UseConfirmModal';
import UseAlertModal from '../Common/UseAlertModal';

const BookingQrRead = (props) => {
  // const [bookData, setBookData] = useState(null);
  const [message, setMessage] = useState('');
  const [scanning, setScanning] = useState(true);
  const [indication, setIndication] = useState(false);
  const [bookData, setBookData] = useState(null);
  // const [bookModalData, setBookModalData] = useState(null);
  const [logiList, setLogiMaster] = useState([]);
  const [placesVisitList, setPlacesVisitMaster] = useState([]);
  const [guardPersonList, setGuardPersonMaster] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => () => {});
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');

  const [searchData, setSearchData] = useState({
    logi_id: props.user.logiId,
    place_visit_id: '',
    card_number: '',
  })
  const [cameraData, setCameraData] = useState({
    logi_id: props.user.logiId,
    guard_person_id: '',
  })
  const [mode, setMode] = useState(constClass.POINT_SCREEN.CAMERA);

  const [bookModalData, setBookModalData] = useState({
    site_id: props.siteId,
    book_id: '',
    logi_id: '',
    logi_name: '',
    place_visit_id: '',
    place_visit_name: '',
    bookingdate: '',
    start_time: '',
    end_time: '',
    names: [''], //氏名
    identity_document: [''], //本人確認書類
    card_number: [''], //カード番号
    return_card: [''], //返却
    bonded_area: [''], //保税エリア
    baggage_inspection: [''], //手荷物検査
    physical_examination: [''], //身体検査
    metal_detector: [''], //金属探知機
    others_identity_document: [''],
    company_name: '',
    phone_number: '',
    person_charge: '',
    requirement: '',
    others_requirement: '',
    vehicle_number: '', //車両番号
    parking_number: '', //駐車場番号
    remarks: '',
    status: '',
    guard_entry: '',
    guard_closing: '',
  });

  const modalStyle = {

    overlay: {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    content: {
      left: '10%',
      right: '10%',
      top: '15%',
      bottom: 'auto',
      padding: "0px",
      maxHeight: "80%",
      width: "80%",
      overflowX: "hidden",
      pointerEvents: "auto",
      backgroundClip: "padding-box",
      border: "1px rgba(0,0,0,.2)",
      overflow: "visible",
    }

  };

  const componentRef = useRef();
  const { addToast } = useToasts();

  const refreshMaster = useCallback(async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');
    const params = {
      site_id: props.siteId,
    }
    const logiPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/logi/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const logi = (await logiPromise).data;
    const placesVisitPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/places_visit/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const placesVisit = (await placesVisitPromise).data;
    const guardPersonPromise = axios.post(`${process.env.REACT_APP_BACKEND_URL}/guard_person/listalldate/`, params, { headers: { Authorization: `Bearer ${jwt}` } });
    const guardPerson = (await guardPersonPromise).data;

    const nowDate = new Date();
    var book_reg_params = {
      "site_id": props.siteId,
      "operator": "and",
      "where": [
        { "site_id": props.siteId },
        { operator: 'eq', attr: 'bookingdate', val: nowDate },
        { "operator": "or", "where": [{ "status": constClass.STATUS.BFE }, { "status": constClass.STATUS.ARE }, { "status": constClass.STATUS.ACA }] }
      ]
    }

    // 施設を変更することはほぼないため依存配列
    if (searchData.logi_id) {
      book_reg_params.where.push({ "logi_id": searchData.logi_id });
    }
    var data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard_search/name`, book_reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    setBookData(data);
    setPlacesVisitMaster(placesVisit);
    setLogiMaster(logi);
    setGuardPersonMaster(guardPerson);
  }, [props.siteId, searchData.logi_id]);

  const setQrCodeData = async (qrData) => {
    if (qrData) {
      // var winRef;
      setScanning(false);

      const jwt = localStorage.getItem('jwt');
      const siteId = props.siteId;

      try {
        //予約情報を取得
        const booking_data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/qrdata/${siteId}`, { qr_data: qrData }, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        })).data;

        var names = [];
        var identity_document = [];
        var others_identity_document = [];
        var card_number = [];
        var return_card = [];
        var bonded_area = [];
        var baggage_inspection = [];
        var physical_examination = [];
        var metal_detector = [];
        booking_data.t_booking_names.map(async (item, idx) => {
          names.push([item.name, item.name_id]);
          card_number.push(item.card_number);
          return_card.push(item.return_card);
          bonded_area.push(item.bonded_area);
          baggage_inspection.push(item.baggage_inspection);
          physical_examination.push(item.physical_examination);
          metal_detector.push(item.metal_detector);
          if (item.identity_document !== '社員証' && constClass.NAME_LICENCE[item.identity_document]) {
            identity_document.push(constClass.NAME_LICENCE[item.identity_document]);
            others_identity_document.push('');
          }
          else {
            identity_document.push('4')
            const match = item.identity_document.match(/その他\((.*?)\)/);
            const staff_card = item.identity_document.match(/社員証/);
            if (match) {
              others_identity_document.push(match[1]);
            } else if (staff_card) {
              others_identity_document.push(staff_card[0]);
            }
          }
        });

        if (booking_data.logi_id !== Number(cameraData.logi_id)) {
          var tmp = { ...cameraData, guard_person_id: '' };
          setCameraData(tmp);
        }

        setBookModalData({
          site_id: props.siteId,
          book_id: booking_data.book_id ? booking_data.book_id : '',
          logi_id: booking_data.logi_id ? booking_data.logi_id : '',
          logi_name: booking_data.logi_name ? booking_data.logi_name : '',
          place_visit_id: booking_data.place_visit_id ? booking_data.place_visit_id : '',
          place_visit_name: booking_data.place_visit_name ? booking_data.place_visit_name : '',
          bookingdate: booking_data.bookingdate ? booking_data.bookingdate : '',
          start_time: booking_data.start_time ? booking_data.start_time : '',
          end_time: booking_data.end_time ? booking_data.end_time : '',
          names: names ? names : '', //氏名
          identity_document: identity_document ? identity_document : '', //本人確認書類
          card_number: card_number ? card_number : '', //カード番号
          return_card: return_card ? return_card : '', //返却
          bonded_area: bonded_area ? bonded_area : '', //保税エリア
          baggage_inspection: baggage_inspection ? baggage_inspection : '', //手荷物検査
          physical_examination: physical_examination ? physical_examination : '', //身体検査
          metal_detector: metal_detector ? metal_detector : '', //金属探知機
          others_identity_document: others_identity_document ? others_identity_document : '',
          company_name: booking_data.company_name ? booking_data.company_name : '',
          phone_number: booking_data.phone_number ? booking_data.phone_number : '',
          person_charge: booking_data.person_charge ? booking_data.person_charge : '',
          requirement: constClass.NAME_REQUIREMENT[booking_data.requirement] ? constClass.NAME_REQUIREMENT[booking_data.requirement] : '4',
          others_requirement: constClass.NAME_REQUIREMENT[booking_data.requirement] ? '' : booking_data.requirement.match(/その他\((.*?)\)/)[1],
          vehicle_number: booking_data.vehicle_number ? booking_data.vehicle_number : '', //車両番号
          parking_number: booking_data.parking_number ? booking_data.parking_number : '', //駐車場番号
          remarks: booking_data.remarks ? booking_data.remarks : '',
          status: booking_data.status ? booking_data.status : '',
        });
      } catch (err) {
      }

      setMode(constClass.POINT_SCREEN.MAIN);
      setIndication(true)
      setScanning(true);

    } else {
      setMessage('読み取りに失敗しました。再度読み取りをお願いします。');
    }
  };

  const newAdmission = async () => {
    setBookModalData({
      site_id: props.siteId,
      book_id: '',
      logi_id: cameraData.logi_id ? cameraData.logi_id : '',
      logi_name: cameraData.logi_id ? logiList.find(x => x.logi_id === Number(cameraData.logi_id)).logi_name : '',
      place_visit_id: '',
      place_visit_name: '',
      bookingdate: '',
      start_time: '',
      end_time: '',
      names: [''], //氏名
      identity_document: [''], //本人確認書類
      card_number: [''], //カード番号
      return_card: [''], //返却
      bonded_area: [''], //保税エリア
      baggage_inspection: [''], //手荷物検査
      physical_examination: [''], //身体検査
      metal_detector: [''], //金属探知機
      others_identity_document: [''],
      company_name: '',
      phone_number: '',
      person_charge: '',
      requirement: '',
      others_requirement: '',
      vehicle_number: '', //車両番号
      parking_number: '', //駐車場番号
      remarks: '',
      status: constClass.STATUS.BFE,
      guard_entry: '',
      guard_closing: '',
      guard_person_id: cameraData.guard_person_id ? cameraData.guard_person_id : ''
    });
    setMode(constClass.POINT_SCREEN.MAIN);
    setIndication(true);
  };

  const alreadyAdmission = async (book_id) => {
    var booking_data = bookData.find((book) => book.book_id === Number(book_id));

    var names = [];
    var identity_document = [];
    var others_identity_document = [];
    var card_number = [];
    var return_card = [];
    var bonded_area = [];
    var baggage_inspection = [];
    var physical_examination = [];
    var metal_detector = [];
    booking_data.t_booking_names.map(async (item, idx) => {
      names.push([item.name, item.name_id]);
      card_number.push(item.card_number);
      return_card.push(item.return_card);
      bonded_area.push(item.bonded_area);
      baggage_inspection.push(item.baggage_inspection);
      physical_examination.push(item.physical_examination);
      metal_detector.push(item.metal_detector);
      if (item.identity_document !== '社員証' && constClass.NAME_LICENCE[item.identity_document]) {
        identity_document.push(constClass.NAME_LICENCE[item.identity_document]);
        others_identity_document.push('');
      }
      else {
        identity_document.push('4')
        const match = item.identity_document.match(/その他\((.*?)\)/);
        const staff_card = item.identity_document.match(/社員証/);
        if (match) {
          others_identity_document.push(match[1]);
        } else if (staff_card) {
          others_identity_document.push(staff_card[0]);
        }
      }
    });

    if (booking_data.logi_id !== cameraData.logi_id) {
      var tmp = { ...cameraData, guard_person_id: '' };
      setCameraData(tmp);
    }

    setBookModalData({
      site_id: props.siteId,
      book_id: booking_data.book_id ? booking_data.book_id : '',
      logi_id: booking_data.logi_id ? booking_data.logi_id : '',
      logi_name: booking_data.logi_name ? booking_data.logi_name : '',
      place_visit_id: booking_data.place_visit_id ? booking_data.place_visit_id : '',
      place_visit_name: booking_data.place_visit_name ? booking_data.place_visit_name : '',
      bookingdate: booking_data.bookingdate ? booking_data.bookingdate : '',
      start_time: booking_data.start_time ? booking_data.start_time : '',
      end_time: booking_data.end_time ? booking_data.end_time : '',
      names: names ? names : '', //氏名
      identity_document: identity_document ? identity_document : '', //本人確認書類
      card_number: card_number ? card_number : '', //カード番号
      return_card: return_card ? return_card : '', //返却
      bonded_area: bonded_area ? bonded_area : '', //保税エリア
      baggage_inspection: baggage_inspection ? baggage_inspection : '', //手荷物検査
      physical_examination: physical_examination ? physical_examination : '', //身体検査
      metal_detector: metal_detector ? metal_detector : '', //金属探知機
      others_identity_document: others_identity_document ? others_identity_document : '',
      company_name: booking_data.company_name ? booking_data.company_name : '',
      phone_number: booking_data.phone_number ? booking_data.phone_number : '',
      person_charge: booking_data.person_charge ? booking_data.person_charge : '',
      requirement: constClass.NAME_REQUIREMENT[booking_data.requirement] ? constClass.NAME_REQUIREMENT[booking_data.requirement] : '4',
      others_requirement: constClass.NAME_REQUIREMENT[booking_data.requirement] ? '' : booking_data.requirement.match(/その他\((.*?)\)/)[1],
      vehicle_number: booking_data.vehicle_number ? booking_data.vehicle_number : '', //車両番号
      parking_number: booking_data.parking_number ? booking_data.parking_number : '', //駐車場番号
      remarks: booking_data.remarks ? booking_data.remarks : '',
      status: booking_data.status ? booking_data.status : '',
    });

    setMode(constClass.POINT_SCREEN.MAIN);
    setIndication(true);
  };

  const searchBin = async () => {
    //トークンを取得
    const jwt = localStorage.getItem('jwt');

    const nowDate = new Date();
    var book_reg_params = {
      "site_id": props.siteId,
      "operator": "and",
      "where": [
        { "site_id": props.siteId },
        { operator: 'eq', attr: 'bookingdate', val: nowDate },
        { "operator": "or", "where": [{ "status": constClass.STATUS.BFE }, { "status": constClass.STATUS.ARE }, { "status": constClass.STATUS.ACA }] }
      ]
    }

    // 施設
    if (searchData.logi_id) {
      book_reg_params.where.push({ "logi_id": searchData.logi_id });
    }
    // 訪問先
    if (searchData.place_visit_id) {
      book_reg_params.where.push({ "place_visit_id": searchData.place_visit_id });
    }
    var data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard_search/name`, book_reg_params, { headers: { Authorization: `Bearer ${jwt}` } })).data;
    if (searchData.card_number) {
      data = data.filter(t => t.t_booking_names.find(c => c.card_number === searchData.card_number));
    }
    setBookData(data);
  };

  const handleLogi = async (e) => {
    const target = e.target;
    var tmp = { ...searchData, logi_id: target.value };
    setSearchData(tmp);
  }

  const handleModalLogi = async (value) => {
    var tmp = { ...bookModalData, logi_id: value, logi_name: logiList.find(x => x.logi_id === Number(value)) ? logiList.find(x => x.logi_id === Number(value)).logi_name : '' };
    setBookModalData(tmp);
  }

  const handlePlacesVisit = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...searchData, place_visit_id: target.value };
    setSearchData(tmp);
  }

  const handleModalPlacesVisit = async (value) => {
    var tmp = { ...bookModalData, place_visit_id: value, place_visit_name: placesVisitList.find(x => x.place_visit_id === Number(value)) ? placesVisitList.find(x => x.place_visit_id === Number(value)).place_visit_name : '' };
    setBookModalData(tmp);
  }

  const handleCardNumber = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...searchData, card_number: target.value };
    setSearchData(tmp);
  }

  const handleCameraLogi = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...cameraData, logi_id: target.value, guard_person_id: '' };
    setCameraData(tmp);
  }

  const handleCameraGuard = async (e) => {
    //トークンを取得
    const target = e.target;
    var tmp = { ...cameraData, guard_person_id: target.value };
    setCameraData(tmp);
  }

  const checkInvalid = () => {
    var result = false;
    // 基本情報の確認
    if (!bookModalData.logi_id || !bookModalData.place_visit_id || !bookModalData.bookingdate || !bookModalData.start_time || !bookModalData.end_time || !bookModalData.bookingdate || !bookModalData.company_name || !bookModalData.phone_number || bookModalData.names.some(item => item === '' || item === null) || bookModalData.names.some(item => item[0] === '' || item[0] === null) || bookModalData.identity_document.some(item => item === "" || item === null) || !bookModalData.person_charge || !bookModalData.requirement || (bookModalData.requirement === '4' && !bookModalData.others_requirement)) {
      result = true;
    }
    for (var i = 0; i < bookModalData.names.length; i++) {
      if (bookModalData.identity_document[i] === "4" && !bookModalData.others_identity_document[i]) {
        result = true;
      }
    }
    return result;
  }

  const handleChange = (index, event) => {
    var newData = {};
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    if (name === 'identity_document') {
      const identity_document = [...bookModalData.identity_document];
      identity_document[index] = value;
      newData = { ...bookModalData, identity_document: identity_document };
    } else if (name === 'others_identity_document') {
      const others_identity_document = [...bookModalData.others_identity_document];
      others_identity_document[index] = value;
      newData = { ...bookModalData, others_identity_document: others_identity_document };
    } else if (name === 'card_number') {
      const card_number = [...bookModalData.card_number];
      card_number[index] = value;
      newData = { ...bookModalData, card_number: card_number };
    } else if (name === 'return_card') {
      const return_card = [...bookModalData.return_card];
      return_card[index] = value;
      newData = { ...bookModalData, return_card: return_card };
    } else if (name === 'bonded_area') {
      const bonded_area = [...bookModalData.bonded_area];
      bonded_area[index] = value;
      newData = { ...bookModalData, bonded_area: bonded_area };
    } else if (name === 'baggage_inspection') {
      const baggage_inspection = [...bookModalData.baggage_inspection];
      baggage_inspection[index] = value;
      newData = { ...bookModalData, baggage_inspection: baggage_inspection };
    } else if (name === 'physical_examination') {
      const physical_examination = [...bookModalData.physical_examination];
      physical_examination[index] = value;
      newData = { ...bookModalData, physical_examination: physical_examination };
    } else if (name === 'metal_detector') {
      const metal_detector = [...bookModalData.metal_detector];
      metal_detector[index] = value;
      newData = { ...bookModalData, metal_detector: metal_detector };
    }
    setBookModalData(newData);
  };

  const handleChange1 = (index, event) => {
    var newData = {};
    const identity_document = [...bookModalData.identity_document];
    identity_document[index] = event.target.value;
    newData = { ...bookModalData, identity_document: identity_document };
    setBookModalData(newData);
  };

  const handleChange2 = (index, event, name_id) => {
    var newData = {};
    const names = [...bookModalData.names];
    if (name_id) {
      names[index] = ([event.target.value, name_id]);
    }
    else {
      names[index] = event.target.value;
    }
    newData = { ...bookModalData, names: names };
    setBookModalData(newData);
  };

  const addNameInput = () => {
    setBookModalData(prevState => ({
      ...prevState,
      names: [...prevState.names, ''],
      identity_document: [...prevState.identity_document, ''],
      others_identity_document: [...prevState.others_identity_document, ''],
      card_number: [...prevState.card_number, ''],
      return_card: [...prevState.return_card, ''],
      bonded_area: [...prevState.bonded_area, ''],
      physical_examination: [...prevState.physical_examination, ''],
      metal_detector: [...prevState.metal_detector, ''],
    }));
  };

  const removeNameInput = index => {
    setBookModalData(prevState => ({
      ...prevState,
      names: prevState.names.filter((_, i) => i !== index),
      identity_document: prevState.identity_document.filter((_, i) => i !== index),
      others_identity_document: prevState.others_identity_document.filter((_, i) => i !== index),
      card_number: prevState.card_number.filter((_, i) => i !== index),
      return_card: prevState.return_card.filter((_, i) => i !== index),
      bonded_area: prevState.bonded_area.filter((_, i) => i !== index),
      physical_examination: prevState.physical_examination.filter((_, i) => i !== index),
      metal_detector: prevState.metal_detector.filter((_, i) => i !== index),
    }));
  };

  const changeDate = (value) => {
    setBookModalData({ ...bookModalData, bookingdate: value });
  }

  const changeBook = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? (target.checked ? constClass.FLAG.ON : constClass.FLAG.OFF) : target.value;
    const name = target.name;
    setBookModalData({ ...bookModalData, [name]: value });
  }

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
  };

  const handleEntryClick = async () => {
    if (!cameraData.guard_person_id) {
      setAlertModalMessage('守衛を選択してください')
      setIsAlertModalOpen(true);
      return;
    }
    const jwt = localStorage.getItem('jwt');
    const regex = /^\(?0[0-9\-()]{9,12}$/;
    if (!regex.test(bookModalData.phone_number)) {
      setAlertModalMessage('※正しい形式で電話番号を入力してください')
      setIsAlertModalOpen(true);
      return;
    }
    //トークンを取得
    // const params = {
    //   site_id: props.match.params.siteId,
    //   status: constClass.STATUS.ARE,
    //   upd_name: props.user.userId,
    // };
    var res;
    if (bookModalData.card_number.some(item => item === "" || item === null)) {
      //Modalに表示するメッセージをセット
      setConfirmModalMessage('カードNO.が未入力ですがよろしいですか？')
      setIsConfirmModalOpen(true);
      setOnConfirm(() => () => {
        setConfirmModalMessage('入館しますか？');
        setIsConfirmModalOpen(true);
        setOnConfirm(() => async () => {
          closeConfirmModal();
          try {
            bookModalData.status = constClass.STATUS.ARE;
            bookModalData.guard_entry = guardPersonList.find((item) => item.logi_id === Number(bookModalData.logi_id) && item.guard_person_id === Number(cameraData.guard_person_id)).name;
            if (!bookModalData.book_id) {
              res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/modal`, bookModalData, { headers: { Authorization: `Bearer ${jwt}` } });
            } else {
              res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/modal/${bookModalData.book_id}`, bookModalData, { headers: { Authorization: `Bearer ${jwt}` } });
            }
            refreshMaster();
            setMode(constClass.POINT_SCREEN.CAMERA)
            setBookModalData({
              site_id: props.siteId,
              book_id: '',
              logi_id: '',
              logi_name: '',
              place_visit_id: '',
              place_visit_name: '',
              bookingdate: '',
              start_time: '',
              end_time: '',
              names: [''], //氏名
              identity_document: [''], //本人確認書類
              card_number: [''], //カード番号
              return_card: [''], //返却
              bonded_area: [''], //保税エリア
              baggage_inspection: [''], //手荷物検査
              physical_examination: [''], //身体検査
              metal_detector: [''], //金属探知機
              others_identity_document: [''],
              company_name: '',
              phone_number: '',
              person_charge: '',
              requirement: '',
              others_requirement: '',
              vehicle_number: '', //車両番号
              parking_number: '', //駐車場番号
              remarks: '',
              status: '',
              guard_entry: '',
              guard_closing: '',
            });
            if (res.data && res.data.result) {
              addToast(`ステータスを更新しました。`, { appearance: 'success', autoDismiss: true });
            } else {
              setAlertModalMessage(`ステータスの更新に失敗しました\n${res.data && res.data.message ? res.data.message : ""}`)
              setIsAlertModalOpen(true);
            }
          } catch (err) {
            if (err.response.data !== null) {
              addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
            } else {
              addToast(err.response, { appearance: 'error', autoDismiss: true });
            }
          }
        });
      });
    } else {
      setIsConfirmModalOpen(true);
      setConfirmModalMessage('入館しますか？');
      setOnConfirm(() => async () => {
        closeConfirmModal();
        try {
          bookModalData.status = constClass.STATUS.ARE;
          bookModalData.guard_entry = guardPersonList.find((item) => item.logi_id === Number(bookModalData.logi_id) && item.guard_person_id === Number(cameraData.guard_person_id)).name;
          if (!bookModalData.book_id) {
            res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/modal`, bookModalData, { headers: { Authorization: `Bearer ${jwt}` } });
          } else {
            res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/modal/${bookModalData.book_id}`, bookModalData, { headers: { Authorization: `Bearer ${jwt}` } });
          }
          refreshMaster();
          setMode(constClass.POINT_SCREEN.CAMERA)
          setBookModalData({
            site_id: props.siteId,
            book_id: '',
            logi_id: '',
            logi_name: '',
            place_visit_id: '',
            place_visit_name: '',
            bookingdate: '',
            start_time: '',
            end_time: '',
            names: [''], //氏名
            identity_document: [''], //本人確認書類
            card_number: [''], //カード番号
            return_card: [''], //返却
            bonded_area: [''], //保税エリア
            baggage_inspection: [''], //手荷物検査
            physical_examination: [''], //身体検査
            metal_detector: [''], //金属探知機
            others_identity_document: [''],
            company_name: '',
            phone_number: '',
            person_charge: '',
            requirement: '',
            others_requirement: '',
            vehicle_number: '', //車両番号
            parking_number: '', //駐車場番号
            remarks: '',
            status: '',
            guard_entry: '',
            guard_closing: '',
          });
          if (res.data && res.data.result) {
            addToast(`ステータスを更新しました。`, { appearance: 'success', autoDismiss: true });
          } else {
            setAlertModalMessage(`ステータスの更新に失敗しました\n${res.data && res.data.message ? res.data.message : ""}`)
            setIsAlertModalOpen(true);
          }
        } catch (err) {
          if (err.response.data !== null) {
            addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
          } else {
            addToast(err.response, { appearance: 'error', autoDismiss: true });
          }
        }
      });
    }
  };

  const handleClosingClick = async () => {
    if (!cameraData.guard_person_id) {
      setAlertModalMessage('守衛を選択してください')
      setIsAlertModalOpen(true);
      return;
    }
    const jwt = localStorage.getItem('jwt');
    const regex = /^\(?0[0-9\-()]{9,12}$/;
    if (!regex.test(bookModalData.phone_number)) {
      setAlertModalMessage('※正しい形式で電話番号を入力してください')
      setIsAlertModalOpen(true);
      return;
    }
    setIsConfirmModalOpen(true);
    setConfirmModalMessage('退館しますか？');
    setOnConfirm(() => async () => {
      closeConfirmModal();
      try {
        var res;
        bookModalData.status = constClass.STATUS.AMO;
        bookModalData.guard_closing = guardPersonList.find((item) => item.logi_id === Number(bookModalData.logi_id) && item.guard_person_id === Number(cameraData.guard_person_id)).name;
        res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/booking/backyard/modal/${bookModalData.book_id}`, bookModalData, { headers: { Authorization: `Bearer ${jwt}` } });
        refreshMaster();
        setMode(constClass.POINT_SCREEN.CAMERA)
        setBookModalData({
          site_id: props.siteId,
          book_id: '',
          logi_id: '',
          logi_name: '',
          place_visit_id: '',
          place_visit_name: '',
          bookingdate: '',
          start_time: '',
          end_time: '',
          names: [''], //氏名
          identity_document: [''], //本人確認書類
          card_number: [''], //カード番号
          return_card: [''], //返却
          bonded_area: [''], //保税エリア
          baggage_inspection: [''], //手荷物検査
          physical_examination: [''], //身体検査
          metal_detector: [''], //金属探知機
          others_identity_document: [''],
          company_name: '',
          phone_number: '',
          person_charge: '',
          requirement: '',
          others_requirement: '',
          vehicle_number: '', //車両番号
          parking_number: '', //駐車場番号
          remarks: '',
          status: '',
          guard_entry: '',
          guard_closing: '',
        });
        if (res.data && res.data.result) {
          addToast('ステータスを更新しました。', { appearance: 'success', autoDismiss: true });
        } else {
          setAlertModalMessage(`ステータスの更新に失敗しました\n${res.data && res.data.message ? res.data.message : ""}`)
          setIsAlertModalOpen(true);
        }
      } catch (err) {
        if (err.response.data !== null) {
          addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
        } else {
          addToast(err.response, { appearance: 'error', autoDismiss: true });
        }
      }
    });
  }

  const handleReturnClick = async (e) => {
    setMode(constClass.POINT_SCREEN.CAMERA)
    if (bookModalData.logi_id !== Number(cameraData.logi_id)) {
      var tmp = { ...cameraData, guard_person_id: '' };
      setCameraData(tmp);
    }
    setBookModalData({
      site_id: props.siteId,
      book_id: '',
      logi_id: '',
      logi_name: '',
      place_visit_id: '',
      place_visit_name: '',
      bookingdate: '',
      start_time: '',
      end_time: '',
      names: [''], //氏名
      identity_document: [''], //本人確認書類
      card_number: [''], //カード番号
      return_card: [''], //返却
      bonded_area: [''], //保税エリア
      baggage_inspection: [''], //手荷物検査
      physical_examination: [''], //身体検査
      metal_detector: [''], //金属探知機
      others_identity_document: [''],
      company_name: '',
      phone_number: '',
      person_charge: '',
      requirement: '',
      others_requirement: '',
      vehicle_number: '', //車両番号
      parking_number: '', //駐車場番号
      remarks: '',
      status: '',
      guard_entry: '',
      guard_closing: '',
    });
  }

useEffect(() => {
  var intervalId;
  async function fetchData() {
    await refreshMaster();
    intervalId = setInterval(() => {
      refreshMaster();
    }, 60000);
  }
  fetchData();
  return () => {
    clearInterval(intervalId);
  };
}, [refreshMaster]);

  const renderList = () => {
    return (
      <div ref={componentRef} className="print-list size">
        <div className="container-fluid">
          {bookModalData && mode === constClass.POINT_SCREEN.MAIN &&
            <Modal isOpen={indication} style={modalStyle} onRequestClose={() => setScanning(false)}>
              <div className='row mb-3 m-4'>
                <div className="col-2">
                  <div className="col text-green font-weight-bold text-center">
                    <button className="m-4 btn btn-active px-3 text-nowrap text-center" onClick={handleReturnClick}>戻る</button>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center">
                    予約ID
                  </div>
                </div>
                <div className="col-3 align-items-center justify-content-center">
                  <div className="col-sm-12 csv-input text-left">
                    {bookModalData.book_id ? bookModalData.book_id : "新規"}
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>施設
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <select className="custom-select w-100 text-center" aria-label="施設"
                      value={bookModalData.logi_id || ''}
                      onChange={(e) => handleModalLogi(e.target.value)}
                      disabled={bookModalData.status === constClass.STATUS.CCL}
                    >
                      <option value="" >- 選択 -</option>
                      {logiList.map((item, idx) =>
                        <option key={idx} value={item.logi_id}>{item.logi_name}</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>訪問先
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <select className="custom-select w-100" aria-label="訪問先"
                      value={bookModalData.place_visit_id || ''}
                      onChange={(e) => handleModalPlacesVisit(e.target.value)}
                      disabled={bookModalData.status === constClass.STATUS.CCL || !bookModalData.logi_id}
                    >
                      <option value="">選択してください</option>
                      {bookModalData.logi_id && placesVisitList.filter((visit) => visit.logi_id === Number(bookModalData.logi_id)).map((item) =>
                        <option key={item.place_visit_id} value={item.place_visit_id}>{item.place_visit_name}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>日付
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <DatePicker className={`form-control`} aria_label="日付" locale="ja" dateFormat="yyyy/MM/dd" monthsShown="1" id="bookingDate" name="bookingDate"
                      selected={(!bookModalData.bookingdate || bookModalData.bookingdate === '') ? null : moment(bookModalData.bookingdate, 'YYYY-MM-DD').toDate()}
                      minDate={new Date()}
                      onChange={date => changeDate(date ? moment(date).format('YYYY-MM-DD') : null)}
                      placeholderText="- 選択 -"
                      disabledKeyboardNavigation
                      disabled={bookModalData.status === constClass.STATUS.CCL}
                      onFocus={(e) => e.target.blur()}
                    />
                  </div>
                </div>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>時間帯
                  </div>
                </div>
                <div className="col-2">
                  <div className="col-sm-12 csv-input">
                    <select
                      className="custom-select w-100 text-center"
                      aria-label="開始時間"
                      value={bookModalData.start_time || ''}
                      onChange={e => changeBook(e)}
                      name='start_time'
                      disabled={bookModalData.status === constClass.STATUS.CCL}
                    >
                      {/* Number(reservation.end_time.substring(0, 2)) <= hour */}
                      <option value="">- 選択 -</option>
                      {[...Array(25).keys()].map((hour) => {
                          return (
                            <React.Fragment key={hour}>
                              <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                {`${hour.toString().padStart(2, '0')}時00分`}
                              </option>
                              {hour !== 24 &&
                                <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                  {`${hour.toString().padStart(2, '0')}時30分`}
                                </option>
                              }
                            </React.Fragment>
                          );
                      })}
                    </select>
                  </div>
                </div>
                ～
                <div className="col-2">
                  <div className="col-sm-12 csv-input">
                    {/* <input type="text" className="form-control" id={"end_time"} name={"end_time"} value={bookData.end_time} onChange={e => changeBook(e)}></input> */}
                    <select
                      className="custom-select w-100 text-center"
                      aria-label="終了時間"
                      value={bookModalData.end_time || ''}
                      onChange={e => changeBook(e)}
                      name='end_time'
                      disabled={bookModalData.status === constClass.STATUS.CCL}
                    >
                      <option value="">- 選択 -</option>
                      {[...Array(25).keys()].map((hour) => {
                        if (bookModalData.start_time && Number(bookModalData.start_time.substring(0, 2)) <= hour) {
                          if (Number(bookModalData.start_time.substring(0, 2)) === hour && Number(bookModalData.start_time.substring(3, 5)) === 30) {
                            return null;
                          }
                          else {
                            return (
                              <React.Fragment key={hour}>
                                {Number(bookModalData.start_time.substring(0, 2)) !== hour &&
                                  <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                    {`${hour.toString().padStart(2, '0')}時00分`}
                                  </option>
                                }
                                {hour !== 24 &&
                                  <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                    {`${hour.toString().padStart(2, '0')}時30分`}
                                  </option>
                                }
                              </React.Fragment>
                            );
                          }
                        } else if (!bookModalData.start_time) {
                            return (
                              <React.Fragment key={hour}>
                                <option value={`${hour.toString().padStart(2, '0')}:00`}>
                                  {`${hour.toString().padStart(2, '0')}時00分`}
                                </option>
                                {hour !== 24 &&
                                  <option value={`${hour.toString().padStart(2, '0')}:30`}>
                                    {`${hour.toString().padStart(2, '0')}時30分`}
                                  </option>
                                }
                              </React.Fragment>
                            );
                        } else {
                          return null;
                        }
                        //}
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>会社名
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <input type="text" className="form-control" id={"company_name"} name={"company_name"} value={bookModalData.company_name} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                  </div>
                </div>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>電話番号
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <input type="text" className="form-control" id={"phone_number"} name={"phone_number"} value={bookModalData.phone_number} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                  </div>
                </div>
              </div>
              {bookModalData.names.map((name, index) => (
                <div key={index} className="bg-logigreen pt-2 pb-1 mb-2 m-3">
                  <div className='row mb-2'>
                    <div className="col-2">
                      <div className="text-right pt-2 text-nowrap">
                        <span className="text-red">※</span>氏名
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="col-sm-12 csv-input">
                        <input
                          type="text"
                          name="names"
                          className="form-control"
                          value={Array.isArray(name) ? name[0] : name} // 名前の部分だけ表示
                          onChange={e => handleChange2(index, e, Array.isArray(name) ? name[1] : "")}
                          placeholder={`氏名 ${index + 1}`}
                          disabled={bookModalData.status === constClass.STATUS.CCL}
                        />
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="d-flex align-items-center justify-content-center pt-2 text-nowrap">
                        <span className="text-red">※</span>本人確認書類
                      </div>
                    </div>
                    <div className="col-5 mx-1 form-check-inline">
                      {constClass.LICENSE.map((identity_document, idx) => (
                        identity_document !== '2' && identity_document !== '4' && (
                          <div key={idx} className={`custom-control custom-radio`}>
                            <input type="radio" className="custom-control-input" id={`identity_document_${identity_document}_${index}`} onChange={e => handleChange1(index, e)} value={identity_document} checked={bookModalData.identity_document && identity_document === bookModalData.identity_document[index]} disabled={bookModalData.status === constClass.STATUS.CCL} />
                            <label className="custom-control-label text-nowrap" htmlFor={`identity_document_${identity_document}_${index}`}>{constClass.LICENSE_NAME[identity_document]}</label>
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col-6 ml-1'></div>
                    {constClass.LICENSE.map((identity_document, idx) => (
                      identity_document === '4' && (
                        <div key={idx} className={`custom-control custom-radio`}>
                          <input type="radio" className="custom-control-input" id={`identity_document_${identity_document}_${index}`} onChange={e => handleChange1(index, e)} value={identity_document} checked={bookModalData.identity_document && identity_document === bookModalData.identity_document[index]} disabled={bookModalData.status === constClass.STATUS.CCL} />
                          <label className="custom-control-label text-nowrap" htmlFor={`identity_document_${identity_document}_${index}`}>{constClass.LICENSE_NAME[identity_document]}</label>
                        </div>
                      )
                    ))}
                    {bookModalData.identity_document && bookModalData.identity_document[index] === '4' &&
                      <input className="form-control pb-1 ml-1 col-4" name="others_identity_document" value={bookModalData.others_identity_document[index]} onChange={e => handleChange(index, e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                    }
                  </div>
                  <div className='row mx-1 mb-2'>
                    <div className='col-5'></div>
                    <div className="col-1 d-flex align-items-center justify-content-center pt-2 text-nowrap">
                      カードNO.
                    </div>
                    <div className="col-4 text-left">
                      <input
                        type="text"
                        name="card_number"
                        className="form-control mt-2"
                        value={bookModalData.card_number[index] || ''}
                        onChange={e => handleChange(index, e)}
                        disabled={bookModalData.status === constClass.STATUS.CCL}
                      />
                    </div>
                    {bookModalData.status === constClass.STATUS.ACA &&
                      <div className='col-8 text-right mt-2 pr-4'>
                        <div className="custom-control custom-checkbox form-check-reverse">
                          <input type="checkbox" className="custom-control-input" id={`return_card_${index}`} name="return_card" value="true" checked={bookModalData.return_card[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookModalData.status === constClass.STATUS.CCL} />
                          <label className="custom-control-label m-1 text-danger text-nowrap" htmlFor={`return_card_${index}`}>カードは返却しましたか？</label>
                        </div>
                      </div>
                    }
                  </div>
                  <div className='row mx-1 mb-2 ml-3'>
                      <div className='col-5'></div>
                      <div className="col-1">
                        <div className="custom-control custom-checkbox form-check-reverse d-flex align-items-center justify-content-center pt-2 text-nowrap">
                          <input type="checkbox" className="custom-control-input" id={`bonded_area_${index}`} name="bonded_area" value="true" checked={bookModalData.bonded_area[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookModalData.status === constClass.STATUS.CCL} />
                          <label className="custom-control-label m-1 text-right text-nowrap" htmlFor={`bonded_area_${index}`}>保税エリア</label>
                        </div>
                      </div>
                      {bookModalData.bonded_area[index] === constClass.FLAG.ON &&
                        <div className="col-3 text-left text-danger d-flex align-items-center justify-content-center pt-2 text-nowrap">
                          検査は実施しましたか？
                        </div>
                      }
                    </div>
                  
                  {bookModalData.bonded_area[index] === constClass.FLAG.ON &&
                    <div className='col-12 row mx-1 mb-1'>
                      <div className='col-8 ml-4 text-right'>
                        <div className="custom-control custom-checkbox form-check-reverse">
                          <input type="checkbox" className="custom-control-input" id={`baggage_inspection_${index}`} name="baggage_inspection" value="true" checked={bookModalData.baggage_inspection[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookModalData.status === constClass.STATUS.CCL} />
                          <label className="custom-control-label m-1 text-nowrap" htmlFor={`baggage_inspection_${index}`}>手荷物検査</label>
                        </div>
                      </div>
                    </div>
                  }
                  {bookModalData.bonded_area[index] === constClass.FLAG.ON &&
                    <div className='col-12 row mx-1 mb-1'>
                      <div className='col-8 ml-4 text-right'>
                        <div className="custom-control custom-checkbox form-check-reverse">
                          <input type="checkbox" className="custom-control-input" id={`physical_examination_${index}`} name="physical_examination" value="true" checked={bookModalData.physical_examination[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookModalData.status === constClass.STATUS.CCL} />
                          <label className="custom-control-label m-1 text-nowrap" htmlFor={`physical_examination_${index}`}>身体検査　</label>
                        </div>
                      </div>
                    </div>
                  }
                  {bookModalData.bonded_area[index] === constClass.FLAG.ON &&
                    <div className='col-12 row mx-1 mb-1'>
                      <div className='col-8 ml-4 text-right'>
                        <div className="custom-control custom-checkbox form-check-reverse">
                          <input type="checkbox" className="custom-control-input" id={`metal_detector_${index}`} name="metal_detector" value="true" checked={bookModalData.metal_detector[index] === constClass.FLAG.ON} onChange={e => handleChange(index, e)} disabled={bookModalData.status === constClass.STATUS.CCL} />
                          <label className="custom-control-label m-1 text-nowrap" htmlFor={`metal_detector_${index}`}>金属探知機</label>
                        </div>
                      </div>
                    </div>
                  }
                  <div className='row mx-1 mb-3 d-flex align-items-center justify-content-center'>
                    <button className="btn btn-primary" onClick={addNameInput} disabled={bookModalData.status === constClass.STATUS.CCL}>追加</button>
                    {index !== 0 &&
                      <div className="col-auto">
                        <button
                          className="btn btn-danger"
                          onClick={() => removeNameInput(index)}
                          disabled={bookModalData.status === constClass.STATUS.CCL}
                        >
                          削除
                        </button>
                      </div>
                    }
                  </div>
                </div>
              ))}
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>訪問先担当者名
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <input type="text" className="form-control" id={"person_charge"} name={"person_charge"} value={bookModalData.person_charge} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>用件
                  </div>
                </div>
                <div className="row mx-0 px-3-env text-left form-group">
                  {constClass.REQUIREMENT.map((requirement, idx) => (
                    <div key={idx} className={`custom-control custom-radio`}>
                      <input type="radio" className="custom-control-input" id={`requirement_${requirement}`} name="requirement" onChange={e => changeBook(e)} value={requirement} checked={bookModalData.requirement === requirement} disabled={bookModalData.status === constClass.STATUS.CCL} />
                      <label className="custom-control-label text-nowrap" htmlFor={`requirement_${requirement}`}>{constClass.REQUIREMENT_NAME[requirement]}</label>
                    </div>
                  ))}
                </div>
                {bookModalData.requirement === '4' && <input className="form-control mb-1 ml-1 col-4" name="others_requirement" value={bookModalData.others_requirement} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>}
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    車両番号
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <input type="text" className="form-control" id={"vehicle_number"} name={"vehicle_number"} value={bookModalData.vehicle_number} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                  </div>
                </div>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    駐車場No.
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <input type="text" className="form-control" id={"parking_number"} name={"parking_number"} value={bookModalData.parking_number} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    備考
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <input type="text" className="form-control" id={"remarks"} name={"remarks"} value={bookModalData.remarks} onChange={e => changeBook(e)} disabled={bookModalData.status === constClass.STATUS.CCL}></input>
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    ステータス
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    {constClass.STATUS_NAME[bookModalData.status] ? constClass.STATUS_NAME[bookModalData.status] : "入構前"}
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                <div className="col-2">
                  <div className="d-flex align-items-center justify-content-center pt-2">
                    <span className="text-red">※</span>守衛
                  </div>
                </div>
                <div className="col-3">
                  <div className="col-sm-12 csv-input">
                    <select className="custom-select w-100" aria-label="訪問先" disabled={!bookModalData.logi_id} value={cameraData.guard_person_id || ''} onChange={handleCameraGuard}>
                      <option value="">選択してください</option>
                      {bookModalData.logi_id && guardPersonList.filter((guard) => guard.logi_id === Number(bookModalData.logi_id)).map((item) =>
                        <option key={item.guard_person_id} value={item.guard_person_id}>{item.name}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className='row mx-1 d-flex align-items-center justify-content-center'>
                <div className="text-green font-weight-bold text-center">
                  <button className="m-4 btn btn-active px-3 text-nowrap text-center" disabled={checkInvalid() || bookModalData.status !== constClass.STATUS.BFE} onClick={handleEntryClick}>入館</button>
                  <UseConfirmModal isOpen={isConfirmModalOpen} message={confirmModalMessage} onConfirm={onConfirm} onClose={closeConfirmModal}/>
                  <UseAlertModal isOpen={isAlertModalOpen} message={alertModalMessage} onClose={closeAlertModal}/>
                </div>
                <div className="col-auto">
                  <div className="m-4 text-green font-weight-bold text-center">
                    <button className="btn btn-active px-3 text-nowrap text-center" onClick={handleClosingClick} disabled={checkInvalid() || bookModalData.status !== constClass.STATUS.ACA}>退館</button>
                  </div>
                </div>
              </div>
            </Modal>
          }
          <div className="row mb-3 p-0 border-bottom">
            <div className="col-4 p-0 ml-4 mb-4">
              <div className="row mb-3 text-center">
                <div className="row mr-1 text-center align-self-center">
                  <span>施設</span>
                </div>
                <div className="col-5 mr-2 text-center">
                  <select className="custom-select w-100" aria-label="施設" value={cameraData.logi_id || ''} onChange={handleCameraLogi}>
                    <option value="">選択してください</option>
                    {logiList.map((item) =>
                      <option key={item.logi_id} value={item.logi_id}>{item.logi_name}</option>
                    )}
                  </select>
                </div>
                <div className="row mr-1 text-center align-self-center">
                  <span>守衛</span>
                </div>
                <div className="col-5 text-center">
                  <select className="custom-select w-100" aria-label="訪問先" disabled={!cameraData.logi_id} value={cameraData.guard_person_id || ''} onChange={handleCameraGuard}>
                    <option value="">選択してください</option>
                    {cameraData.logi_id && guardPersonList.filter((guard) => guard.logi_id === Number(cameraData.logi_id)).map((item) =>
                      <option key={item.guard_person_id} value={item.guard_person_id}>{item.name}</option>
                    )}
                  </select>
                </div>
              </div>
              {!cameraData.guard_person_id &&
                <div className="row mb-3 text-center">
                  <span className='text-danger font-weight-bold'>※守衛を選択してください</span>
                </div>
              }
              {mode === constClass.POINT_SCREEN.CAMERA && cameraData.guard_person_id && <BookingQrScanner {...props} setMode={setMode} setQrCodeData={setQrCodeData} setBookModalData={setBookModalData} message={message} scanning={scanning} />}
              {mode === constClass.POINT_SCREEN.MAIN && cameraData.guard_person_id &&
                <div className="row">
                  <div className="col text-center">
                    <button
                      className="btn btn-link text-body"
                      onClick={() => setMode(constClass.POINT_SCREEN.CAMERA)}>
                      カメラを起動
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="row col-11 p-0 ml-4 mt-4">
            <div className="row mb-3 text-center">
              <div className="text-center align-self-center">
                <span>施設</span>
              </div>
              <div className="col-3 text-center">
                <select className="custom-select w-100" aria-label="施設" value={searchData.logi_id || ''} onChange={handleLogi}>
                  <option value="">選択してください</option>
                  {logiList.map((item) =>
                    <option key={item.logi_id} value={item.logi_id}>{item.logi_name}</option>
                  )}
                </select>
              </div>
              <div className="text-center align-self-center">
                <span>訪問先</span>
              </div>
              <div className="col-3 text-center">
                <select className="custom-select w-100" aria-label="訪問先" disabled={!searchData.logi_id} value={searchData.place_visit_id || ''} onChange={handlePlacesVisit}>
                  <option value="">選択してください</option>
                  {searchData.logi_id && placesVisitList.filter((visit) => visit.logi_id === Number(searchData.logi_id)).map((item) =>
                    <option key={item.place_visit_id} value={item.place_visit_id}>{item.place_visit_name}</option>
                  )}
                </select>
              </div>
              <div className="text-center align-self-center">
                <span>カードNo.</span>
              </div>
              <div className="col-2 text-center">
                <input
                  type="text"
                  name="card_number"
                  className="form-control"
                  value={searchData.card_number || ''}
                  onChange={handleCardNumber}
                />
              </div>
              <div className="col-1 text-center">
                <div className="col text-green font-weight-bold text-center">
                  <button className='btn btn-active px-3 text-nowrap text-center'
                    onClick={() => searchBin()}>検索</button>
                </div>
              </div>
              <div className="mb-2 ml-4">
                <div className="text-right">
                  <button className="btn btn-active" onClick={() => newAdmission()}>新規登録</button>
                </div>
              </div>
            </div>
            <div className="row mb-0 p-0">
              <div className="text-left align-self-end">
                {(searchData.logi_id && logiList.length > 0) && (
                  <h4>{logiList.find(s => s.logi_id === Number(searchData.logi_id)).logi_name}</h4>
                )}
              </div>
            </div>
            <div className="row">
              <table className="table table-bordered table-striped" height="1">
                <thead className={`table-success`}>
                  <tr>
                    <th className="text-center align-middle text-nowrap like-button">
                      予約<br />ID
                    </th>
                    <th className="text-center align-middle like-button">
                      カードNo.
                    </th>
                    <th className="text-center align-middle like-button">
                      ステータス
                    </th>
                    <th className="text-center align-middle like-button">
                      時間帯
                    </th>
                    <th className="text-center align-middle like-button">
                      施設
                    </th>
                    <th className="text-center align-middle like-button">
                      訪問先
                    </th>
                    <th className="text-center align-middle like-button">
                      訪問先担当者名
                    </th>
                    <th className="text-center align-middle like-button">
                      会社名
                    </th>
                    <th className="text-center align-middle like-button">
                      氏名
                    </th>
                    <th className="text-center align-middle like-button">
                      本人確認書類
                    </th>
                    <th className="text-center align-middle like-button">
                      保税エリア
                    </th>
                    <th className="text-center align-middle like-button">
                      入館確認者
                    </th>
                    <th className="text-center align-middle like-button">
                      入館時刻
                    </th>
                    <th className="text-center align-middle like-button">
                      到着確認者
                    </th>
                    <th className="text-center align-middle like-button">
                      到着確認時刻
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bookData.length <= 0 && (
                    <tr>
                      <td className="text-center align-middle p-3" colSpan={18}>条件に一致する予約がありません</td>
                    </tr>
                  )}
                  {bookData.map((data, idx) => (
                    <tr key={idx}>
                      {/* 予約番号 */}
                      <td className="text-center align-middle p-1">
                        <button className="btn text-success" onClick={() => alreadyAdmission(data.book_id)}>{data.book_id}</button>
                      </td>
                      {/* カードNo. */}
                      <td className="text-center align-middle p-1 text-nowrap">
                        {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                          <div key={nameIdx} className="text-center align-middle p-1">
                            {nameData.card_number}
                          </div>
                        ))}
                      </td>
                      {/* ステータス */}
                      <td className="text-center align-middle p-1">
                        {constClass.STATUS_NAME[data.status]}
                      </td>
                      {/* 時間帯 */}
                      <td className="text-center align-middle p-1">
                        {data.start_time} ～ {data.end_time}
                      </td>
                      {/* 施設 */}
                      <td className="text-center align-middle p-1">
                        {data.logi_name}<br />
                      </td>
                      {/* 訪問先 */}
                      <td className="text-center align-middle p-1">
                        {data.place_visit_name}
                      </td>
                      {/* 訪問先担当者名 */}
                      <td className="text-center align-middle p-1">
                        {data.person_charge}
                      </td>
                      {/* 会社名 */}
                      <td className="text-center align-middle p-1">
                        {data.company_name}
                      </td>
                      <td>
                        {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                          <div key={nameIdx} className="text-center align-middle p-1 text-nowrap">
                            {nameData.name}
                          </div>
                        ))}
                      </td>
                      <td>
                        {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                          <div key={nameIdx} className="text-center align-middle p-1 text-nowrap">
                            {nameData.identity_document.match(/その他\((.*?)\)/) ? "その他" : nameData.identity_document}
                          </div>
                        ))}
                      </td>
                      <td>
                        {data.t_booking_names && data.t_booking_names.map((nameData, nameIdx) => (
                          <div key={nameIdx} className="text-center align-middle p-1">
                            {nameData.bonded_area === constClass.FLAG.ON ? "〇" : ""}
                          </div>
                        ))}
                      </td>
                      {/* 入館確認者 */}
                      <td className="text-center align-middle p-1">
                        {data.guard_entry}
                      </td>
                      {/* 入館時刻 */}
                      <td className="text-center align-middle p-1">
                        {data.entry_time ? moment(data.entry_time).format("YYYY/MM/DD HH:mm:ss") : ''}
                      </td>
                      {/* 到着確認者 */}
                      <td className="text-center align-middle p-1">
                        {data.confirmation_person}
                      </td>
                      {/* 到着確認時刻 */}
                      <td className="text-center align-middle p-1">
                        {data.confirmation_time ? moment(data.confirmation_time).format("YYYY/MM/DD HH:mm:ss") : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container-fluid">
      <div className="row d-none"><div className="col-12">{util.inspect(bookData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(props.user)}</div></div>
      {bookData === null && <div className="row"><div className="col-12">読み込み中・・・</div></div>}
      {bookData !== null && renderList()}
      {/* {renderHeader()} */}
    </div>
  )
}

export default BookingQrRead;